.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row > button {
  margin-left: 4px;
  margin-right: 8px;
}

.row:not(:last-child) {
  margin-bottom: 16px;
}

.value {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.button {
  appearance: none;
  background: none;
  font-size: 12px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.textbox {
  font-size: 12px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 4px;
}

.button:hover,
.button:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.button:disabled {
  color: darkgray;
  cursor: not-allowed;
}

.button:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.asyncButton {
  position: relative;
}

.asyncButton:after {
  content: '';
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s;
}

.boardGrid {
  display: grid;
  grid-template-columns: 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px;
  grid-template-rows: 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px;
  grid-auto-flow: row;
}

.letterGrid {
  display: grid;
  grid-template-columns: 32px 32px 32px 32px 32px 32px;
  grid-template-rows: 32px 32px 32px 32px 32px;
  grid-auto-flow: row;
}

.tile {
  display: grid;
  grid-template-columns: 10% auto 30%;
  grid-template-rows: 10% auto 30%;

  background-color: burlywood;
  border-color: brown;
  width: 30px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
}

.dragTile {
  opacity: 50%;
}

.workingTile {
  background-color: greenyellow;
}

.lastPlayedTile {
  background-color: violet;
}

.rackEndSquare {
  background-color: whitesmoke;
  border-color: tomato;
  border-style: dashed;
}

.tileLetter {
  font-size: 12pt;
  text-align: center;
  grid-column-start: 2;
  grid-row-start: 2;
}

.blankTile {
  color: red;
}

.tileValue {
  font-size: 6pt;
  font-family: 'Courier New', Courier, monospace;
  grid-column-start: 3;
  grid-row-start: 3;
}

.boardSquare {
  background-color: beige;
  border-color: black;
  width: 32px;
  height: 32px;
  padding: 0px;
  border-style: solid;
  border-width: 1px;
}

.boardTripleWord {
  background-color: orange;
}

.boardTripleLetter {
  background-color: darkblue;
}

.boardDoubleWord {
  background-color: pink;
}

.boardDoubleLetter {
  background-color: lightblue;
}
